@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .heading {
    @apply text-5xl font-semibold pl-6;
  }
}
thead {
  font-size: 1.1rem;
}
table {
  background: rgba(191, 195, 253, 0.08);
  border-radius: 12px;
  border-color: white;
  font-size: 1.1rem;
  color: white;
}
.edit-modal {
  position: relative;
  z-index: 20;
}
.modal_style {
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translate(-50%, -50%);
  background-color: #3a3641;
  z-index: 1010;
  box-shadow: 0px 0px 20px rgba(22, 21, 21, 0.50);
}
.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.8;
  z-index: 1000;
  filter: blur(56px);
}
